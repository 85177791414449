.box-container {
    border: 1px solid black;
    padding: 10px;
    margin: 10px auto;
    max-width: 100%;
    border-radius: 4px;
    background-color: #ffffff;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* transition: box-shadow 0.2s ease; */
}
/* 
.box-container:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
} */